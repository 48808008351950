.aspect-ratio-before {
    width: 1px;
    margin-left: -1px;
    float: left;
    height: 0;
    padding-top: 591.44px / 1127.34px * 100%;
}

.aspect-ratio-after {
    /* to clear float */
    display: table;
    clear: both;
}
