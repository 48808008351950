.add-collection-button-container {
    position: absolute;
    bottom: var(--g-m);
    right: var(--g-ml);
    z-index: 5;
}

.collections-section {
    margin-bottom: 40px;
}

.guide-editor-settings__map-container {
    display: flex;
    align-items: stretch;
    justify-content: center;
}

.guide-editor-settings__map-preview {
    height: 400px;
    width: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0;
}

.guide-editor-settings__header {
    font-size: 16px;
    line-height: 24px;
}

.guide-editor-settings__map-text {
    max-width: 230px;
    margin-right: auto;
    font-size: 12px;
    line-height: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.guide-editor-collections__filter-button-container {
    position: relative;
}

.guide-editor-collections__filter-button {
    position: absolute;
    top: 0;
    right: 0;
}

.guide-editor-collections__option.is-useless {
    color: rgb(0 0 0 / 45%);
}

.guide-editor-basic-info__container {
    display: grid;
    column-gap: var(--g-ml);
    grid-template-columns: minmax(300px, 1fr) 372px;
}

.form-field__datepicker {
    width: 100%;
}

.guide-editor-tips-nested-table .ant-spin-nested-loading .ant-table {
    margin: -16px 0 !important;
}

.guide-editor-tips-nested-table tbody > tr {
    background-color: unset !important;
}

.guide-editor-tips-nested-table tbody > tr > td {
    vertical-align: middle;
}

.guide-editor-tips-spot-reviewed {
    color: #1890ff !important;
    border-color: #1890ff !important;
}
