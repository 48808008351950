:root {
    --media-selector__popover-width: 620px;
    --media-selector__popover-height: 500px;
}

.media-selector-preview {
    position: relative;
    background: #e6f7ff;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
}

.media-selector-preview__img {
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover;
    background-position: center center;
    position: absolute;
    inset: 0;
    z-index: 1;
}

.media-selector-preview.can-select-image .media-selector-preview__img {
    cursor: pointer;
}

.media-selector-preview__img-icon {
    font-size: 32px;
}

.media-selector__modal .ant-modal-body {
    background-color: var(--antd-page-bg);
}

.media-selector__tools {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: var(--g-m) var(--g-sm);
}

.media-selector__loading {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 300px;
}

.media-selector-image-list-container {
    max-height: calc(100vh - 137px);
    overflow: auto;
}

.media-selector-image-list-container__hide {
    display: none;
}

.media-selector-image-list {
    display: flex;
    flex-wrap: wrap;
    padding: 5px;
    user-select: none;
}

.media-selector-image-list-item {
    margin: 5px;

    --image-size: 190px;

    height: var(--image-size);
    width: var(--image-size);
    box-sizing: border-box;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    background-color: rgb(0 0 0 / 0%);
    transition: background-color ease-in;
    transition-delay: 0;
    transition-duration: 0.1s;
}

.media-selector-image-list-item:hover {
    background-color: rgb(0 0 0 / 5%);
    transition-delay: 0.2s;
    transition-duration: 0.3s;
}

.media-selector-image-list-item img {
    height: 100%;
    width: 100%;
    object-fit: contain;
}

.media-selector-image-list-item-video {
    position: absolute;
    bottom: 5px;
    right: 5px;
    height: 20px;
    width: 20px;
    border-radius: 50%;
    background: rgb(0 0 0 / 30%);
    display: flex;
    align-items: center;
    justify-content: center;
}

.media-selector-image-list-item-video img {
    height: 10px;
    width: 12px;
    object-fit: contain;
}

.media-selector-image-list-item-selected {
    position: absolute;
    bottom: 5px;
    right: 5px;
    z-index: 1;
    transition: opacity 0.2s ease-out;
    opacity: 0;
}

.media-selector-image-list-item.is-selected
    .media-selector-image-list-item-selected {
    opacity: 1;
}

.media-selector-image-list-item-loading {
    position: absolute;
    bottom: 5px;
    right: 5px;
    z-index: 3;
}

.media-selector-image-list-item-favorite {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 1;
}

.media-selector-image-list-item-extra-content {
    position: absolute;
    bottom: 5px;
    right: 5px;
    z-index: 4;
}

.media-selector-image-list-loading {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 12px 0;
    height: 100%;
}

.media-selector-location-input {
    margin-top: var(--g-xs);
    padding: 5px 10px;
    max-width: 480px;
    width: 480px;
}

.media-cropper-modal {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 15px;
}

.media-selector-no-results {
    padding: 12px;
}

.media-selector-modal__loading {
    position: absolute;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1;
    background: rgb(255 255 255 / 30%);
}

.media-selector-modal__searchbox {
    max-width: 481px;
}
